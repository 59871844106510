import react, { useContext, useEffect, useState } from "react";
import { Form, FormGroup, Input, Row, Col, FormFeedback } from "reactstrap";
import Map from "../components/Map/map";
import "../assets/css/contacto.css";
import Footer from "components/footer/footer";
import LanguageContext from "context/context-language";

let cont = {
  es: [
    "Permítenos ayudarte a mejorar tu compañía",
    "Nombre",
    "Empresa",
    "Correo",
    "Número de teléfono",
    "Describre tu proyecto",
    "Enviar",
    "Ventas",
    "Reportes",
    "Call Center",
  ],
  en: [
    "Allow us to help improve your company",
    "Name",
    "Company",
    "Email",
    "Phone number",
    "Describe your project",
    "Send",
    "Sales",
    "Reports",
    "Call Center",
  ],
};

function Contacto() {
  const { language, handleLanguage } = useContext(LanguageContext);
  const [nombre, setNombre] = useState("");
  const [empresa, setEmpresa] = useState("");
  const [correo, setCorreo] = useState("");
  const [telefono, setTelefono] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [validN, setValidN] = useState(true);
  const [validC, setValidC] = useState(true);
  const [validT, setValidT] = useState(true);
  const [errorC, setErrorC] = useState(undefined);
  const [res, setRes] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const textos = cont[language];
  useEffect(() => {
    const element = document.getElementById("inicio");
    element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
  }, []);

  const validate_form = () => {
    const arr = [];
    if (nombre === "" || nombre === undefined) {
      arr.push(false);
      setValidN(false);
    } else {
      setValidN(true);
    }
    if (correo === "" || correo === undefined || errorC !== undefined) {
      arr.push(false);
      setValidC(false);
    } else {
      if (errorC === "Formato de correo incorrecto") {
        setValidC(false);
      } else {
        setValidC(true);
        setErrorC(undefined);
      }
    }
    if (telefono === "" || telefono === undefined) {
      arr.push(false);
      setValidT(false);
    } else {
      setValidT(true);
    }
    return arr;
  };
  const handleKeyPress = (e) => {
    let key = e.charCode;
    if (!((key >= 48 && key <= 57) || key === 45)) {
      e.preventDefault();
    }
  };
  const validateEmail = (value) => {
    let validate = value
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );

    if (validate) {
      setCorreo(value);
      setValidC(true);
      setErrorC(undefined);
    } else {
      setCorreo(value);
      setValidC(false);
      setErrorC("Formato de correo incorecto");
    }
  };
  const saveData = (e) => {
    const validate_forms = validate_form();

    if (!validate_forms.includes(false)) {
      setIsLoading(true);
      axios
        .post(
          //'https://dummy-dot-softel-net.uc.r.appspot.com/api/v1/email/',
          "https://contacs-dot-softel-net.uc.r.appspot.com/api/v1/email/",
          {
            nombre: nombre,
            empresa: empresa,
            correo: correo,
            telefono: telefono,
            descripcion: descripcion,
          }
        )
        .then((res) => {
          setRes(true);
          setTimeout(() => {
            setIsLoading(false);
            setRes(false);
          }, 4000);
        });
    }
  };

  return (
    <>
      <div id="inicio"></div>
      <div className="container-contacto">
        <div className="title-contecto">{textos[0]}</div>
        <Row md="2" sm="1" xs="1">
          {/* <Col sm={{'order':  2}} xs={{'order':  2}}>   */}
          <Col>
            <Form style={{ background: "transparent" }}>
              <FormGroup style={{ background: "transparent" }}>
                <Input
                  placeholder={textos[1]}
                  style={{ background: "transparent", color: "#fff" }}
                  className="inputForm"
                  onChange={(e) => {
                    if (e.target.value === "") {
                      validN(false);
                    } else {
                      validN(true);
                    }
                    setNombre(e.target.value);
                  }}
                  name="nombre"
                  invalid={!validN}
                />
                <FormFeedback style={{ background: "transparent" }}>Nombre requerido</FormFeedback>
              </FormGroup>
              <FormGroup style={{ background: "transparent" }}>
                <Input
                  placeholder={textos[2]}
                  style={{ background: "transparent", color: "#fff" }}
                  className="inputForm"
                  onChange={(e) => setEmpresa(e.target.value)}
                />
              </FormGroup>
              <FormGroup style={{ background: "transparent" }}>
                <Input
                  placeholder={textos[3]}
                  style={{ background: "transparent", color: "#fff" }}
                  className="inputForm"
                  onChange={(e) => {
                    validateEmail(e.target.value);
                  }}
                  name="correo"
                  invalid={validC !== true}
                  type="email"
                />
                <FormFeedback style={{ background: "transparent" }}>
                  {correo === "" ? "Correo requerido" : errorC}
                </FormFeedback>
              </FormGroup>
              <FormGroup style={{ background: "transparent" }}>
                <Input
                  placeholder={textos[4]}
                  style={{ background: "transparent", color: "#fff" }}
                  className="inputForm"
                  onChange={(e) => {
                    if (e.target.value === "") {
                      validT(false);
                    } else {
                      validT(true);
                    }
                    setTelefono(e.target.value);
                  }}
                  name="telefono"
                  invalid={!validT}
                  onKeyPress={(e) => handleKeyPress(e)}
                />
                <FormFeedback style={{ background: "transparent" }}>Teléfono requerido</FormFeedback>
              </FormGroup>
              <FormGroup style={{ background: "transparent" }}>
                <Input
                  type="textarea"
                  placeholder={textos[5]}
                  style={{ background: "transparent", color: "#fff", height: "100px" }}
                  className="inputForm"
                  onChange={(e) => setDescripcion(e.target.value)}
                />
              </FormGroup>
              {isLoading === true ? (
                <div style={{ background: "transparent", position: "relative", width: "100px", height: "40px" }}>
                  <PuffLoader color="#36d7b7" size={30} style={{ background: "transparent" }} />
                </div>
              ) : (
                <div className="btn-more" onClick={(e) => saveData(e)}>
                  {textos[6]}
                </div>
              )}

              {res && (
                <div
                  style={{
                    position: "relative",
                    width: "100%",
                    fontWeight: "normal",
                    marginTop: "10px",
                    fontSize: "12px",
                    textAlign: "center",
                    padding: "5px",
                    borderRadius: "10px",
                    backgroundColor: "#d4edda",
                    color: "#155724",
                    borderColor: "#c3e6cb",
                  }}
                >
                  Información enviada exitosamente
                </div>
              )}
            </Form>
          </Col>
          {/* <Col sm={{'order':  1}} xs={{'order':  1}}>   */}
          <Col>
            <div className="container-info-contacto">
              <div className="info-softel">
                <div className="cnt1">
                  <div style={{ background: "transparent", color: "#fff", fontWeight: "bold" }}>
                    SOFTEL S.A. de C.V.
                  </div>
                  <div style={{ background: "transparent", color: "#fff", fontWeight: "normal", fontSize: "15px" }}>
                    Camino a Santa Teresa 187 C, Piso 5, <br /> Parques del Pedregal, 14010, Tlalpan, CDMX.
                  </div>
                </div>
                <div className="cnt2">
                  <Row md="3" sm="1" xs="1" style={{ height: "100%" }}>
                    <Col className="col1">
                      <div style={{ height: "50px" }}>
                        <span style={{ background: "transparent", fontWeight: "bold" }}>{textos[7]}</span>
                        <br />
                        <span style={{ background: "transparent", fontSize: "15px" }}>
                          {/* 55 3233 1437 <br/> */}
                          55 8045 3683 <br />
                          sales@softel.mx
                        </span>
                      </div>
                    </Col>
                    <Col className="col2">
                      <div style={{ height: "50px" }}>
                        <span style={{ background: "transparent", fontWeight: "bold" }}>{textos[8]}</span>
                        <br />
                        <span style={{ background: "transparent", fontSize: "15px" }}>
                          55 5447 1040 <br /> reportes@softel.mx{" "}
                        </span>
                      </div>
                    </Col>
                    <Col className="col3">
                      <div style={{ height: "50px" }}>
                        <span style={{ background: "transparent", fontWeight: "bold" }}>{textos[9]}</span>
                        <br />
                        <span style={{ background: "transparent", fontSize: "15px" }}>55 5447 1060</span>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="cnt3">
                  <Map />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <Footer />
    </>
  );
}

export default Contacto;
