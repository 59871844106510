import React, { useContext, useState, useRef, createRef, useEffect } from "react";
import "assets/css/panelHeader.css";
import optionMenu from "variables/menu";
import { NavLink } from "react-router-dom";
import { Icon } from "@iconify/react";
import iconDown from "@iconify/icons-ic/baseline-arrow-drop-down";
import iconUp from "@iconify/icons-ic/baseline-arrow-drop-up";
import world from "@iconify/icons-clarity/world-outline-badged";
import Close from "@iconify/icons-ic/close";
import Open from "@iconify/icons-ic/menu";
import PanelHeaderResponsive from "./panelHeaderResponsive";
import LanguageContext from "context/context-language";
import useIsVisible from "components/casos_exito/useIntersection";
import { useLocation } from "react-router-dom";

function PanelHeader() {
  const [isOpen, setIsOpen] = useState(false);
  const { language, handleLanguage } = useContext(LanguageContext);

  const elementsRef = useRef(["opc0", "opc1", "opc2", "opc3"].map(() => createRef()));
  const isVisible0 = useIsVisible(elementsRef.current[0]);
  const isVisible1 = useIsVisible(elementsRef.current[1]);
  const isVisible2 = useIsVisible(elementsRef.current[2]);
  const isVisible3 = useIsVisible(elementsRef.current[3]);
  const { pathname } = useLocation();
  let split = pathname.split("/")[2];
  const [active, setActive] = useState(undefined);

  useEffect(() => {
    setActive(split === undefined ? "home" : split);
  }, [split]);

  let menu = optionMenu[language];
  const generarOpciones = (id) => {
    let subMenu = menu[id][1];
    let Sub = subMenu[1][1];

    let splitS = undefined;
    if (Sub) {
      splitS = Sub.split("/");
    }
    return (
      <>
        <div id={id} ref={elementsRef.current[id]}>
          {subMenu[1][1] && (
            <div className="title-submenu">
              <NavLink to={subMenu[1][1]}>
                <div style={{ position: "auto" }} className={active === splitS[2] ? "pathActive" : ""}>
                  {subMenu[1][0]}
                </div>{" "}
                <div className="arrow-menu" style={{ left: id === 0 ? "260px" : "80px" }}></div>
              </NavLink>
            </div>
          )}

          <div className="subtitle-menu">{subMenu[0]}</div>
          <div className="contain-submenu">
            {subMenu.map((item, key) => {
              let opt = item[1];
              let splitI = undefined;
              if (opt) {
                splitI = opt.split("/")[2];
              }
              return key > 1 && item ? (
                <>
                  <NavLink to={item[1]}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        marginBottom: "10px",
                        textAlign: "left",
                      }}
                    >
                      <img src={item[2]} style={{ marginRight: "10px" }} alt="img" />
                      <div key={key} style={{ fontWeight: "normal", color: split === splitI ? "#004A88" : "" }}>
                        {item[0]}
                      </div>
                    </div>
                  </NavLink>
                </>
              ) : null;
            })}
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="panelHeader">
        <div className="panelHeader-logo">
          <NavLink to={"/"}>
            <img src={require("assets/img/logo_SOFTEL.png")} alt="logo SOFTEL" />
          </NavLink>
        </div>
        <div className="btn-menuResponsive">
          {isOpen == true ? (
            <div className="btn-openMenu" onClick={() => setIsOpen(false)}>
              <Icon icon={Close} />
            </div>
          ) : (
            <div className="btn-openMenu" onClick={() => setIsOpen(true)}>
              {" "}
              <Icon icon={Open} />
            </div>
          )}
        </div>

        <div className="panelHeader-menu">
          <div className="dropdown">
            <div className={`dropbtn ${isVisible0 === true ? "dropbtnActive" : ""}`}>
              {menu[0][0]}
              <Icon icon={isVisible0 === true ? iconUp : iconDown} />
            </div>
            <div className="dropdown-content">{generarOpciones(0)}</div>
          </div>
          <div className="dropdown">
            <div className={`dropbtn ${isVisible1 === true ? "dropbtnActive" : ""}`}>
              {menu[1][0]}
              <Icon icon={isVisible1 === true ? iconUp : iconDown} />
            </div>
            <div className="dropdown-content">{generarOpciones(1)}</div>
          </div>
          <div className="dropdown">
            <NavLink to="/contacto">{menu[2][0]}</NavLink>
          </div>
          <div className="dropdown">
            <div className={`dropbtn ${isVisible3 === true ? "dropbtnActive" : ""}`}>
              {menu[3][0]}
              <Icon icon={isVisible3 === true ? iconUp : iconDown} />
            </div>
            <div className="dropdown-content">{generarOpciones(3)}</div>
          </div>
          <div className="dropdown">
            <div className={`dropbtn ${isVisible2 === true ? "dropbtnActive" : ""}`}>
              <Icon icon={world} style={{ marginRight: "6px" }} /> {language.toUpperCase()}
              <Icon icon={isVisible2 === true ? iconUp : iconDown} />
            </div>
            <div className="dropdown-content">
              <div ref={elementsRef.current[2]}>
                <div className="subtitle-menu" style={{ fontWeight: "normal" }}>
                  {menu[4][1][0]}
                </div>
                <div
                  style={{ display: "flex", justifyContent: "flex-end", fontWeight: "normal", marginBottom: "10px" }}
                >
                  <div style={{ fontSize: "12px", marginRight: "10px" }}>
                    <input
                      type="radio"
                      value={"es"}
                      name="gender"
                      checked={language === "es"}
                      onChange={() => handleLanguage("es")}
                    />{" "}
                    {menu[4][1][1]}
                  </div>
                  <div style={{ fontSize: "12px", marginRight: "5px" }}>
                    <input
                      type="radio"
                      value={"en"}
                      name="gender"
                      checked={language === "en"}
                      onChange={() => handleLanguage("en")}
                    />{" "}
                    {menu[4][1][2]}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isOpen === true && <PanelHeaderResponsive isOpen={(e) => setIsOpen(false)} />}
    </>
  );
}

export default PanelHeader;
