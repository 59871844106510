import React, {Component} from 'react';

import "assets/css/aviso.css";
import Footer from 'components/footer/footer';

class Aviso extends Component {
    constructor(props) {
        super(props);        
        this.state = {
            carga: true
        }        
    }
    componentDidMount(){
        var elmnt = document.getElementById("inicio");
        elmnt.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
        setTimeout(() => {   
            this.setState({
                carga: false
            });            
        }, 500);
    }
    render(){
        return(
            <>
                <div id='inicio'></div>
                <div className='headerAviso'>
                    <div className='divAvisoTitulo'>
                    Aviso de Privacidad
                    </div>
                </div>
                <div className='bodyAviso'>
                    <p>
                        En cumplimiento con lo establecido en la Ley Federal de Protección de Datos Personales en Posesión de 
                        los Particulares (en lo sucesivo la “Ley”), publicada en el Diario Oficial de la Federación el 
                        5 de julio de 2010, el presente Aviso de Privacidad tiene como objeto informarle sobre el tratamiento 
                        que se le dará a sus datos personales cuando los mismos sean recabados, utilizados, almacenados y/o 
                        transferidos por <span className='spanNegrita'><span className='spanNegrita'>SOFTEL </span>, S.A. de C.V.</span>
                    </p>
                    <br/>
                    
                    <p>
                        <span className='spanNegrita'>Responsable del tratamiento de sus datos personales</span>
                    </p>
                    <p>
                        <span className='spanNegrita'> <span className='spanNegrita'>SOFTEL </span>, S. A. de C. V. </span> (en adelante “<span className='spanNegrita'><span className='spanNegrita'>SOFTEL </span> </span>”) con domicilio en Camino a Santa Teresa 187 C, 
                        Piso 5, Colonia Parques del Pedregal, Alcaldía Tlalpan, CDMX; C. P. 14010, será el responsable y 
                        quien decidirá sobre el tratamiento que se le dé a sus datos personales.
                    </p>
                    <br/>
                    <p>
                        <span className='spanNegrita'>Datos personales que se recaban:</span>
                    </p>
                    <p>
                        <span className='spanNegrita'><span className='spanNegrita'>SOFTEL </span> </span>podrá recabar y, en su caso, tratar los siguientes datos personales, dependiendo de la relación que con usted exista:
                        <ul>
                            <li>Nombre completo</li>
                            <li>Edad</li>
                            <li>Fecha de nacimiento</li>
                            <li>Género</li>
                            <li>Estado Civil</li>
                            <li>Domicilio</li>
                            <li>Nacionalidad</li>
                            <li>Correo electrónico</li>
                            <li>Teléfono particular, del trabajo, y/o celular</li>
                            <li>Datos de contacto</li>
                            <li>Forma de contacto preferida</li>
                            <li>RFC</li>
                            <li>Datos curriculares</li>
                        </ul>
                    </p>
                    <p>
                    Podrán tratarse otros datos personales que no se incluyan en la 
                    lista anterior siempre y cuando dichos datos estén relacionados con el objeto social de <span className='spanNegrita'><span className='spanNegrita'>SOFTEL </span> </span>.            
                    </p>
                    <br/>
                    <p>
                        <span className='spanNegrita'>Finalidades del tratamiento de los datos personales </span>
                    </p>
                    <p>
                        <span className='spanNegrita'><span className='spanNegrita'>SOFTEL </span> </span>tratará sus datos personales, en la medida en que la Ley lo permita, para:
                    </p>
                    <p>
                        <ol type="a">
                            <li>Comercializar los servicios que presta y/o vender o de cualquier manera enajenar sus productos;</li>
                            <li>Adquirir de cualquier manera las partes, instrumentos y materiales que requiere;</li>
                            <li>Almacenar datos, crear y diseñar software, programar sistemas de cómputo;</li>
                            <li>Prestar los servicios de telecomunicaciones contratados u otros que preste <span className='spanNegrita'>SOFTEL </span>;</li>
                            <li>Contestar solicitudes de información;</li>
                            <li>Mantenerlo actualizado en cambios a los procesos de telecomunicaciones y u otros servicios y/o productos y otras situaciones que consideremos lo puedan llegar afectar;</li>
                            <li>Dar cumplimiento a obligaciones contraídas;</li>
                            <li>Para controlar el acceso y mantener la seguridad en nuestras instalaciones;</li>
                            <li>Dar contestación a solicitudes de empleo;</li>
                            <li>En general para fines informativos, administrativos y/o comerciales relacionados con los productos y/o servicios requeridos.</li>
                        </ol>
                    </p>
                    <p>
                        Sus datos personales serán tratados únicamente por el tiempo necesario a fin de cumplir 
                        con las finalidades descritas y/o de conformidad con lo que establezca la Ley.
                    </p>
                    <br/>
                    <p>
                        <span className='spanNegrita'>Seguridad de los datos personales </span>
                    </p>
                    <p>
                        <span className='spanNegrita'><span className='spanNegrita'>SOFTEL </span> </span>implementará las medidas de seguridad, técnicas, 
                        administrativas y físicas, necesarias para proteger sus datos personales y evitar su daño, 
                        pérdida, alteración, destrucción o el uso, acceso o tratamiento no autorizado.
                    </p>
                    <br/>
                    <p>
                        <span className='spanNegrita'>Comunicaciones y transferencias de datos personales </span>
                    </p>
                    <p>
                        <span className='spanNegrita'>SOFTEL </span> podrá transferir sus datos personales a terceros únicamente con el fin de cumplir con 
                        las finalidades descritas en el presente aviso.
                    </p>
                    <p>
                        <span className='spanNegrita'>SOFTEL </span> no cederá, venderá o transferirá sus datos personales, a terceros no relacionados con 
                        <span className='spanNegrita'>SOFTEL </span>, sin su consentimiento previo. Sin embargo, <span className='spanNegrita'>SOFTEL </span> podrá transferir sus datos personales en los casos previstos en la Ley.
                    </p>
                    <p>
                        El sitio de Internet de <span className='spanNegrita'>SOFTEL </span> y mensajes de correo electrónico pueden contener vínculos a sitios de internet 
                        externos que no corresponden a <span className='spanNegrita'>SOFTEL </span>. <span className='spanNegrita'>SOFTEL </span> no ha revisado los avisos de privacidad de dichos sitios por lo que 
                        le recomendamos revisar y leer las políticas de privacidad de dichos sitios externos antes de hacer uso de los mismos.
                    </p>
                    <p>
                        Parte de nuestras instalaciones son videograbadas por las cámaras de seguridad de <span className='spanNegrita'>SOFTEL </span>, 
                        ubicadas en el mismo domicilio que arriba se citó. Las imágenes y sonidos captadas por dichas cámaras 
                        serán utilizadas para controlar el acceso y mantener la seguridad en nuestras instalaciones.
                    </p>
                    <br/>
                    <p>
                        <span className='spanNegrita'>Derechos que le corresponden </span>
                    </p>
                    <p>
                        Usted como titular de datos personales podrá ejercitar sus derechos de acceso, rectificación, 
                        cancelación y oposición respecto al tratamiento de sus datos personales en cualquier momento. Asimismo, 
                        podrá revocar el consentimiento que haya otorgado y que fuere necesario para el tratamiento de sus datos personales.
                    </p>
                    <p>
                        Lo anterior podrá realizarse mediante solicitud por escrito con los requisitos que se indican en la Ley entregado por:
                    </p>
                    <p>
                        <ol type="a">
                            <li>
                                Correo electrónico a la siguiente dirección: sales@<span className='spanNegrita'>SOFTEL </span>.mx acreditando su identidad 
                                mediante el envío de fotocopia de cualquier identificación oficial, entre ellos Credencial de Elector
                                 (INE o IFE), Pasaporte, etc., o en caso de actuar en representación de un tercero, deberá adjuntar copia 
                                 de la escritura pública del poder o carta poder otorgada por el titular;
                            </li>
                            <li>
                                Directamente en las oficinas de <span className='spanNegrita'>SOFTEL </span> ubicadas en el domicilio indicado al inicio 
                                del presente aviso, con la acreditación correspondiente mencionada en el inciso a) anterior.
                            </li>
                        </ol>
                    </p>
                    <p>
                        <span className='spanNegrita'>SOFTEL </span> tendrá un plazo máximo de veinte días naturales, 
                        contados a partir de la fecha en que recibió la solicitud de acceso, 
                        rectificación, cancelación u oposición, para comunicarle si se acepta o no la 
                        solicitud, y en caso de que así sea, <span className='spanNegrita'>SOFTEL </span> tiene un plazo de quince días naturales siguientes a 
                        la fecha en que se comunique la respuesta al solicitante para realizar las modificaciones correspondientes. 
                        Los plazos antes referidos podrán ser ampliados una sola vez por un periodo igual, siempre y cuando sea justificado por <span className='spanNegrita'>SOFTEL </span>.
                    </p>
                    <p>
                        <span className='spanNegrita'>SOFTEL </span> podrá negar el acceso, rectificación, cancelación u oposición a los datos personales del titular, cuando:
                    </p>
                    <p>
                        <ol type="a">
                            <li>Se refiera a las partes de un contrato privado, social o administrativo y sean necesarios para su desarrollo y cumplimiento;</li>
                            <li>Deban ser tratados por disposición legal;</li>
                            <li>Obstaculice actuaciones judiciales o administrativas vinculadas a obligaciones fiscales, la investigación y persecución de delitos o la actualización de sanciones administrativas;</li>
                            <li>Sean necesarios para proteger los intereses jurídicamente tutelados del titular;</li>
                            <li>Sean necesarios para realizar una acción en función del interés público;</li>
                            <li>El solicitante no sea el titular de los datos personales, o el representante legal no esté debidamente acreditado para ello;</li>
                            <li>No se encuentren los datos personales del solicitante en los archivos de <span className='spanNegrita'>SOFTEL </span>.</li>
                        </ol>
                    </p>
                    <p>                        
                        En todos los casos anteriores, <span className='spanNegrita'>SOFTEL </span> le informará el motivo de su decisión, acompañando, en su caso, las pruebas que resulten pertinentes.
                    </p>
                    <p>
                        <span className='spanNegrita'>SOFTEL </span> se reserva el derecho de enmendar o modificar el presente Aviso de Privacidad como estime conveniente, por ejemplo, para cumplir con cambios a la Ley o para cumplir con disposiciones internas de <span className='spanNegrita'>SOFTEL </span>.
                    </p>
                    <p>
                        <span className='spanNegrita'>[Última actualización: 1° de marzo de 2019] </span>
                    </p>
                    <p>
                        <span className='spanNegrita'>SOFTEL </span> informará a sus clientes y pondrá a su disposición el Aviso de Privacidad actualizado cuando se le hagan cambios significativos al mismo, así como cuando se requiera recabar su consentimiento.
                    </p>
                </div>
                {
                    this.state.carga &&
                    <div className='cargaAviso'>

                    </div>
                }
                <Footer/>
            </>
        )
    }


}

export default Aviso
