import React, { useEffect, useState } from "react";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import PanelHeader from "components/panelHeader/panelHeader";
import { ContactProvider } from "./context/context-contacto";
import Index from "views";
import Detalle from "views/detalle";
import Planes from "views/planes";
import Pagina_Construccion from "views/pagina_construccion";
import Contacto from "views/contacto";
import AcercaDe from "views/acerca_de";
import IFT1 from "views/IFT1";
import IFT2 from "views/IFT2";
import Control_refrigeracion_imbera from "views/contro_refrigeracion_imbera";
import { LanguageProvider } from "context/context-language";
import Aviso from "views/Aviso";
import Politicas from "views/Politicas";

function App() {
  const [language, setLanguage] = useState(undefined);

  useEffect(() => {}, []);

  return (
    <>
      <div className="container-gral">
        <Router>
          <LanguageProvider languageDefault={language}>
            <PanelHeader />
            <Switch>
              <ContactProvider>
                <Route path={"/"} exact component={Index} />
                <Route path={"/servicios/:id?"} component={Detalle} />
                <Route path={"/productos/:id?"} component={Detalle} />
                <Route path={"/nosotros/certificaciones"} component={AcercaDe} />
                <Route path={"/nosotros/politicas"} component={Politicas} />
                <Route path={"/servicios/planes_datos"} component={Planes} />
                <Route path={"/pagina_construccion"} component={Pagina_Construccion} />
                <Route path={"/contacto"} component={Contacto} />
                <Route path={"/acerca_de"} component={AcercaDe} />
                <Route path={"/IFT"} component={IFT1} />
                <Route path={"/IFTD"} component={IFT2} />
                <Route path={"/aviso"} component={Aviso} />
              </ContactProvider>
            </Switch>
          </LanguageProvider>
        </Router>
      </div>
    </>
  );
}

export default App;
