import React, { useEffect } from "react";
import "assets/css/index.css";
import Carousel from "components/carousel/carousel";
import Casos_exito from "components/casos_exito/casos_exito";
import Footer from "components/footer/footer";
import Contacto from "components/contacto/contacto";
import { Helmet } from "react-helmet";
import var_carousel from "variables/carousel";

function Index() {
  useEffect(() => {
    const element = document.getElementById("inicio");
    element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    var_carousel["es"].map((item) => {
      let url = item.url;
      url = url.toString();
      let link = document.createElement("link");
      link.rel = "preload";
      link.as = "image";
      link.href = url;
      //document.head.appendChild(link);
    });
  }, []);
  return (
    <>
      <div id="inicio"></div>
      <div className="container-index">
        <div className="page">
          <Carousel />
        </div>
        <div className="page">
          <Casos_exito />
        </div>
        <Footer />
      </div>
      <Contacto />
    </>
  );
}

export default Index;
