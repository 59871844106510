import React, { useEffect } from 'react';
import {Row, Col} from 'reactstrap';
import Footer from '../components/footer/footer';
const Servicios = () => {
  useEffect(() => {
    const element = document.getElementById("inicio");
    element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });  
  }, []);
  return (
    <>
      <div id='inicio'></div>
       <div className='headerAboutIFT' id='inicio' style={{top: '70px'}}>
          <div className='divAvisoTitulo'>
          
          </div>
      </div>
      <div className='bodyAviso' style={{marginBottom: '70px'}}>
        <div style={{'borderTop': '1px solid #00577d', 'width': '50px', 'height': '10px'}}></div>
        <p style={{'color': '#00577d', 'fontWeight': 'bold'}}>
          Documentación
        </p>
        <p style={{'color': '#63656a', 'fontSize': '16px',  'fontWeight': 'bold'}}>
          A continuación encontrarás los documentos relacionados con el IFeTel:
        </p>
        <Row md="3" sm="2" xs="1">
          <Col>
            <div className='cardIFT2'>
              <a href={require('../assets/docs/CARTA_DE_DERECHOS_MINIMOS_DE_LOS_USUARIOS_DE_LOS_SERVICIOS_PUBLICOS_DE_TELECOMINICACIONES.pdf')} target='_blank' rel="noopener noreferrer" style={{textDecoration:'none'}}>Carta de los derechos mínimos de los usuarios de los servicios públicos de telecomunicaciones <br/> 
                <span style={{'position': 'relative', 'left': '40%','fontSize': '30px'}}>&#8594;</span>
              </a>              
              
            </div>
          </Col>
          <Col>
            <div className='cardIFT2'>
            <a href={require('../assets/docs/CODIGO_DE_PRACTICAS_COMERCIALES.pdf')} target='_blank' rel="noopener noreferrer" style={{textDecoration:'none'}}>Código de prácticas comerciales<br/>
            <span style={{'position': 'relative', 'left': '40%','fontSize': '30px'}}>&#8594;</span>
            </a>
            </div>
          </Col>
          <Col>
            <div className='cardIFT2'>
              <a href={require('../assets/docs/CONTRATO_DE_ADHESION_DE_TELECOMUNICACIONES.pdf')} target='_blank' rel="noopener noreferrer" style={{textDecoration:'none'}}>Contrato de adhesión<br/>
                <span style={{'position': 'relative', 'left': '40%','fontSize': '30px'}}>&#8594;</span>
              </a>
            </div>
          </Col>          
        </Row>
        <br/>
        <Row md="2" sm="1" xs="1">
          <Col md={4}>
            <div className='cardIFT2'>
              <a href='https://tarifas.ift.org.mx/ift_visor/' target='_blank' rel="noopener noreferrer" style={{textDecoration:'none'}}>Tarifas registradas para líneas de IoT<br/>
              <span style={{'position': 'relative', 'left': '40%','fontSize': '30px'}}>&#8594;</span>
              </a>
            </div>
          </Col>
          <Col md={4}>
            <div className='cardIFT2'>
              <a href={require('../assets/docs/Softel_392-2021.pdf')} target='_blank' rel="noopener noreferrer" style={{textDecoration:'none'}}>Contrato de adhesión para adquisición de líneas IoT <br/>
              <span style={{'position': 'relative', 'left': '40%','fontSize': '30px'}}>&#8594;</span>
              </a>
            </div>
          </Col>

        </Row>
        <p>
          <ul>
            
            
            
            
            
          </ul>        
        </p>
      </div>
      <Footer/>
    </>
  );
};

export default Servicios;