import React, { useState, useEffect, useContext } from "react";
import { Icon } from "@iconify/react";
import copyright from "@iconify/icons-ic/copyright";
import { NavLink } from "react-router-dom";
import "assets/css/footerDetalle.css";
import LanguageContext from "context/context-language";

const text = {
  es: [
    "Diseñado por ",
    "todos los derechos reservados.",
    "Todas las marcas y logotipos que aparecen en este sitio son únicamente para fines ilustrativos y pertenecen a cada uno de sus propietarios.",
    " Aviso de Privacidad",
  ],
  en: [
    "Designed by ",
    " All rights reserved.",
    " All trademarks and logos appearing on this site are for illustrative purposes only and belong to each of their owners.",
    "Notice of Privacy",
  ],
};

function Footer() {
  const { language, handleLanguage } = useContext(LanguageContext);
  const info = text[language];

  return (
    <>
      <div className="container-footer-d">
        <div className="footer-text">
          <div>
            <Icon icon={copyright} /> {1900 + new Date().getYear()}, {info[0]}
            <a href="https://softel.mx" target="_blank" rel="noopener noreferrer" aria-label="softel">
              SOFTEL S.A. de C.V.
            </a>
            {info[1]}
            <br />
            {info[2]}
            <br />
            <NavLink to={"/aviso"}>{info[3]}</NavLink>
          </div>
        </div>
        <div className="footer-icons">
          <div>
            <a
              href="https://www.youtube.com/channel/UCVzHaxJeIRztYjftMxbrwhg"
              target={"_blank"}
              rel="noreferrer"
              aria-label="youtube"
            >
              <div className="icon-y"></div>
            </a>
          </div>
          <div>
            <a
              href="https://www.linkedin.com/company/softelmexico/"
              target={"_blank"}
              rel="noreferrer"
              aria-label="linkedin"
            >
              <div className="icon-i"></div>
            </a>
          </div>
          <div>
            <a href="http://wa.me/+525580453683" target={"_blank"} rel="noreferrer" aria-label="whatsapp">
              <div className="icon-w"></div>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
